import React from 'react'
import ContactForm from './ContactForm'
import './pagestyles.css'

const getContactForm = () => {
   return (
    <div className="contactform">
        
    </div>
   ) 
}


const ContactMe = () => {
    return (
        <div className="contactform">
            <ContactForm />
        </div>
    )
}

export default ContactMe