import React from "react";

const SpHeader = () => {
  return (
    <div className="spheader" key="spheader">
      <h1>Welcome to SlickPixels!</h1>
    </div>
  );
}

export default SpHeader